import axios from '@/common/axios'
import qs from 'qs'

export function addPlantHumidity (data) {
  return axios({
    method: 'post',
    url: '/production/plantHumidity/add',
    data: qs.stringify(data)
  })
}

export function deletePlantHumidity (id) {
  return axios({
    method: 'delete',
    url: '/production/plantHumidity/delete/' + id
  })
}

export function updatePlantHumidity (data) {
  return axios({
    method: 'put',
    url: '/production/plantHumidity/update',
    data: qs.stringify(data)
  })
}

export function selectPlantHumidityInfo (id) {
  return axios({
    method: 'get',
    url: '/production/plantHumidity/info/' + id
  })
}

export function selectPlantHumidityList (query) {
  return axios({
    method: 'get',
    url: '/production/plantHumidity/list',
    params: query
  })
}
