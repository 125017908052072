<template>
  <div id="plantHumidity">
    <el-dialog
      :title="plantHumidityFormTitle"
      width="1500px"
      :visible.sync="plantHumidityDialogVisible"
      :close-on-click-modal="false"
      @close="plantHumidityDialogClose"
    >
      <el-form
        ref="plantHumidityFormRef"
        :model="plantHumidityForm"
        :rules="plantHumidityFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="年度" prop="year">
              <el-input v-model="plantHumidityForm.year" placeholder="请输入年度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="月份" prop="month">
              <el-input v-model="plantHumidityForm.month" placeholder="请输入月份" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="plantHumidityFormTitle === '新增厂房温湿度记录表'
              || plantHumidityFormTitle === '修改厂房温湿度记录表'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="humidityDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="operationDate"
              title="日期"
              width="150"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-colgroup title="XY-J-082（组装车间）" header-align="center">
              <vxe-table-column
                field="temperature1"
                title="温度℃"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="humidity1"
                title="湿度%"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
            </vxe-table-colgroup>
            <vxe-table-column field="result" title="监测结果" width="150">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="合格" />
                  <vxe-radio :label="2" content="不合格" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              v-if="plantHumidityFormTitle === '新增厂房温湿度记录表'
                || plantHumidityFormTitle === '修改厂房温湿度记录表'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="plantHumidityDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="plantHumidityFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="日期">
        <el-date-picker v-model="searchForm.recordDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="plantHumidityPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="year" label="年度" />
      <el-table-column prop="month" label="月份" />
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column label="记录日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="plantHumidityPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addPlantHumidity,
  deletePlantHumidity,
  updatePlantHumidity,
  selectPlantHumidityInfo,
  selectPlantHumidityList
} from '@/api/produce/plantHumidity'

export default {
  data () {
    return {
      plantHumidityDialogVisible: false,
      plantHumidityFormTitle: '',
      plantHumidityForm: {
        id: '',
        year: '',
        month: '',
        humidityDetailJson: ''
      },
      plantHumidityFormRules: {
        recordDate: [{ required: true, message: '日期不能为空', trigger: ['blur', 'change']}]
      },
      plantHumidityPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        recordDate: ''
      },
      humidityDetailList: []
    }
  },
  created () {
    selectPlantHumidityList(this.searchForm).then(res => {
      this.plantHumidityPage = res
    })
  },
  methods: {
    plantHumidityDialogClose () {
      this.$refs.plantHumidityFormRef.resetFields()
      this.humidityDetailList = []
    },
    plantHumidityFormSubmit () {
      if (this.plantHumidityFormTitle === '厂房温湿度记录表详情') {
        this.plantHumidityDialogVisible = false
        return
      }
      this.$refs.plantHumidityFormRef.validate(async valid => {
        if (valid) {
          this.plantHumidityForm.humidityDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.plantHumidityFormTitle === '新增厂房温湿度记录表') {
            this.plantHumidityForm.id = ''
            await addPlantHumidity(this.plantHumidityForm)
          } else if (this.plantHumidityFormTitle === '修改厂房温湿度记录表') {
            await updatePlantHumidity(this.plantHumidityForm)
          }
          this.plantHumidityPage = await selectPlantHumidityList(this.searchForm)
          this.plantHumidityDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.plantHumidityFormTitle = '新增厂房温湿度记录表'
      this.plantHumidityDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePlantHumidity(row.id)
        if (this.plantHumidityPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.plantHumidityPage = await selectPlantHumidityList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.plantHumidityFormTitle = '修改厂房温湿度记录表'
      this.plantHumidityDialogVisible = true
      this.selectPlantHumidityInfoById(row.id)
    },
    handleInfo (index, row) {
      this.plantHumidityFormTitle = '厂房温湿度记录表详情'
      this.plantHumidityDialogVisible = true
      this.selectPlantHumidityInfoById(row.id)
    },
    selectPlantHumidityInfoById (id) {
      selectPlantHumidityInfo(id).then(res => {
        this.plantHumidityForm.id = res.id
        this.plantHumidityForm.year = res.year
        this.plantHumidityForm.month = res.month
        this.humidityDetailList = res.humidityDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPlantHumidityList(this.searchForm).then(res => {
        this.plantHumidityPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPlantHumidityList(this.searchForm).then(res => {
        this.plantHumidityPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPlantHumidityList(this.searchForm).then(res => {
        this.plantHumidityPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
